import revive_payload_client_kvLzfeyUVO from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.16.2_@unocss+reset@0.62.4_axios@1.7.5__bdaf419090d19df935af33180ba3ccab/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EIzcCPeYuX from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.16.2_@unocss+reset@0.62.4_axios@1.7.5__bdaf419090d19df935af33180ba3ccab/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Ii5DxT67v0 from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.16.2_@unocss+reset@0.62.4_axios@1.7.5__bdaf419090d19df935af33180ba3ccab/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VHFTuik0jk from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.16.2_@unocss+reset@0.62.4_axios@1.7.5__bdaf419090d19df935af33180ba3ccab/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/app/apps/onboarding/.nuxt/components.plugin.mjs";
import prefetch_client_QdC9m76oXM from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.16.2_@unocss+reset@0.62.4_axios@1.7.5__bdaf419090d19df935af33180ba3ccab/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_4VcHyAgCHx from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_magicast@0.3.5_rollup@4.21.1_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_z54dYLSMUh from "/app/node_modules/.pnpm/nuxt-viewport@2.1.4_magicast@0.3.5_rollup@4.21.1_vue@3.4.38_typescript@5.5.4_/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import plugin_PEYZQ7Zdyd from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.5_rollup@4.21.1/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_xm59SEfzGq from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.11.1_@parcel+watcher@2.4.1_@type_37416271d0ac22ea4303a8ebc15487e2/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_tU0gpLzRG0 from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@20.16.2_@unocss+reset@0.62.4_axios@1.7.5__bdaf419090d19df935af33180ba3ccab/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _1_gigya_plugin_client_U491P69K7H from "/app/layers/base/plugins/1.gigya-plugin.client.ts";
import _2_user_session_validation_client_BYPNrXf3eW from "/app/layers/base/plugins/2.user-session-validation.client.ts";
import bootstrap_client_n243PjUQtC from "/app/layers/base/plugins/bootstrap.client.ts";
import focus_visible_client_JIkamKZ8y6 from "/app/layers/base/plugins/focus-visible.client.ts";
import http_interceptor_9I5iYs0has from "/app/layers/base/plugins/http-interceptor.ts";
import _0_gigya_plugin_client_VZq2p4KDAf from "/app/apps/onboarding/plugins/0.gigya-plugin.client.ts";
import image_thumbnail_update_G2rT6oNNpa from "/app/apps/onboarding/plugins/image-thumbnail-update.ts";
import page_view_event_client_sZGbScZEgF from "/app/apps/onboarding/plugins/page-view-event.client.ts";
export default [
  revive_payload_client_kvLzfeyUVO,
  unhead_EIzcCPeYuX,
  router_Ii5DxT67v0,
  payload_client_VHFTuik0jk,
  components_plugin_KR1HBZs4kY,
  prefetch_client_QdC9m76oXM,
  i18n_4VcHyAgCHx,
  plugin_client_z54dYLSMUh,
  plugin_PEYZQ7Zdyd,
  plugin_xm59SEfzGq,
  chunk_reload_client_tU0gpLzRG0,
  _1_gigya_plugin_client_U491P69K7H,
  _2_user_session_validation_client_BYPNrXf3eW,
  bootstrap_client_n243PjUQtC,
  focus_visible_client_JIkamKZ8y6,
  http_interceptor_9I5iYs0has,
  _0_gigya_plugin_client_VZq2p4KDAf,
  image_thumbnail_update_G2rT6oNNpa,
  page_view_event_client_sZGbScZEgF
]